import * as React from 'react';

import Link from '@material-ui/core/Link';

export const ImpressumLink: React.FC = () => {
  return (
    <div style={{ textAlign: 'right', width: '100%' }}>
      <Link href="/impressum" color="secondary">
        Impressum
      </Link>
    </div>
  );
};
