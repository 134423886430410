import * as React from 'react';

import Grid from '@material-ui/core/Grid';

import { PageHeader } from '../../components/page/PageHeader';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSection } from '../../components/page/PageSection';
import Typography from '@material-ui/core/Typography';

import { routes } from '../../config';
import Button from '@material-ui/core/Button';

export const Bilder: React.FC = () => {
  return (
    <PageContainer>
      <PageHeader title="Bilder der Hochzeit" />

      <PageSection>
        <Grid item xs={12}>
          <Typography paragraph>
            Wir freuen uns, wenn du noch weitere Bilder hast und sie uns hier hochlädst.
          </Typography>
          <Button variant="contained" href={routes.hochzeitBilderUpload}>
            Bilder hochladen
          </Button>
        </Grid>
      </PageSection>

      <PageSection>
        <Grid item xs={12}>
          <Typography variant="h3">Portaitbilder Regina &amp; Jakob</Typography>
          <Typography paragraph>
            Die Bilder, die am Morgen vor der Trauung am Neckar in Nürtingen entstanden sind.
          </Typography>
          <Button color="primary" variant="outlined" href={routes.hochzeitBilderPortraits}>
            Bilder öffnen
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3">Kirchliche Trauung</Typography>
          <Typography paragraph>
            Die Fotos der kirchlichen Trauung in der Heilig-Geist-Kirche in Großbettlingen.
          </Typography>
          <Button color="primary" variant="outlined" href={routes.hochzeitBilderTrauung}>
            Bilder öffnen
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3">Feier in Kirchheim</Typography>
          <Typography paragraph>Die Fotos der Feier am Abend im Alten Gemeindehaus in Kirchheim.</Typography>
          <Button color="primary" variant="outlined" href={routes.hochzeitBilderFeier}>
            Bilder öffnen
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3">Fotobox</Typography>
          <Typography paragraph>Alle Bilder aus der Fotosbox, in voller Auflösung.</Typography>
          <Button
            color="primary"
            variant="outlined"
            href="https://1drv.ms/f/s!Ah1M_lYklrbWpkGsg6ffz0UBeulr"
            target="_blank"
          >
            Bilder öffnen
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3">Standesamtliche Hochzeit</Typography>
          <Typography paragraph>Die Bilder unserer standesamtlichen Hochzeit am 27.11.2020.</Typography>
          <Button color="primary" variant="outlined" href={routes.hochzeitBilderStandesamt}>
            Bilder öffnen
          </Button>
        </Grid>
      </PageSection>
    </PageContainer>
  );
};
