import { Theme } from '@material-ui/core/styles/createTheme';
import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core/styles';

const indieFlowerTypography = { fontFamily: 'Indie Flower' };
const openSansTypography = { fontFamily: 'Open Sans' };

export const mainPrimary = '#329ea8';
export const lightPrimary = '#53c7b9';

export const theme: Theme = createTheme({
  typography: {
    ...openSansTypography,
    h1: {
      ...indieFlowerTypography,
      fontSize: '3.75rem',
    },
    h2: {
      ...indieFlowerTypography,
      fontSize: '2.5rem',
    },
    h3: {
      ...indieFlowerTypography,
      fontSize: '2.0rem',
    },
    h4: {
      ...indieFlowerTypography,
      fontSize: '1.6rem',
    },
    h5: {
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: mainPrimary,
      light: lightPrimary,
    },
    info: {
      main: mainPrimary,
    },
    secondary: {
      main: '#ffffff',
    },
  },
});
