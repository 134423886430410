import * as React from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { PageHeader } from '../../components/page/PageHeader';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSection } from '../../components/page/PageSection';
import { useGlobalState } from '../../context/GlobalContext';
import { apiBaseUrl, routes } from '../../config';
import { RequestError } from '../../components/feedback/RequestError';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Abmeldung: React.FC = () => {
  const history = useHistory();
  const names = useGlobalState().hochzeit.anmeldung.names;

  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (!names) {
      history.replace(routes.hochzeitAuswahl);
    } else {
      axios
        .post(`${apiBaseUrl}/hochzeit-submit`, {
          names,
          action: 'Abmeldung',
        })
        .then((result) => {
          if (result.status === 200) {
            history.replace(routes.hochzeitAbmeldungSuccess);
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <PageHeader title="Rückmeldung zur Hochzeit" />
      <PageSection>
        <Grid item xs={12}>
          <CircularProgress />
          <Typography>Einen Moment bitte, deine Rückmeldung wird gespeichert...</Typography>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <RequestError />
          </Grid>
        )}
      </PageSection>
    </PageContainer>
  );
};
