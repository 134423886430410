import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { PageHeader } from '../../components/page/PageHeader';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSection } from '../../components/page/PageSection';
import { routes } from '../../config';
import {
  Champagne,
  ChampagneGlass,
  Church,
  FaceMask,
  Highchair,
  Microphone,
  NewlyWeds,
  Platter,
  RiverPlate,
  WeddingCake,
  WeddingGift,
} from './icons/Icons';

export const Info: React.FC = () => {
  return (
    <PageContainer>
      <PageHeader title="Informationen zur Hochzeit" />

      <PageSection>
        <Grid item xs={12}>
          <Typography paragraph align="justify">
            Am 16. Oktober 2021 ist es so weit, dann feiern wir gemeinsam mit euch unsere Hochzeit! Auf dieser Seite
            findet ihr vorab alle wichtigen Informationen, damit ihr bestens für die Hochzeit vorbereitet seid.
          </Typography>
        </Grid>
      </PageSection>

      <Typography variant="h3" align="center" style={{ width: '100%', marginTop: '10px' }}>
        Ablauf
      </Typography>

      <PageSection>
        <Grid item xs={12}>
          <IconBlock icon={Church} title="14:00 Uhr Kirchliche Trauung">
            <Typography paragraph align="justify">
              Die Trauung findet in der Heilig-Geist-Kirche in Großbettlingen statt. Am besten parkt ihr am{' '}
              <Link
                href="https://www.google.com/maps/dir//48.5934135,9.3120457/@48.5929595,9.312188,17z/data=!4m2!4m1!3e2"
                target="_blank"
              >
                Geigersbühlweg 18
              </Link>
              . Die Kirche selbst hat die Adresse:{' '}
              <Link href="https://goo.gl/maps/24xw77NrQvG5aa5w5" target="_blank">
                Goethestraße 5, 72663 Großbettlingen
              </Link>
              .
            </Typography>

            <Typography paragraph align="justify">
              Durch Corona ist es ist leider noch nicht klar, ob im Oktober alle Gäste in die Kirche dürfen. Sollte es
              eine Begrenzung geben, melden wir uns bei einzelnen von euch.
            </Typography>
          </IconBlock>

          <IconBlock icon={ChampagneGlass} title="15:30 Uhr Sektempfang">
            <Typography paragraph align="justify">
              Nach der Trauung findet auf dem Labyrinth-Hof vor der Heilig-Geist-Kirche ein Sektempfang statt.
              Anschließend geht es weiter nach Kirchheim.
            </Typography>
          </IconBlock>

          <IconBlock icon={Champagne} title="17:00 Uhr Beginn der Feier">
            <Typography paragraph align="justify">
              Unsere Feier findet im Alten Gemeindehaus in Kirchheim statt. Parken könnt ihr am besten{' '}
              <Link href="https://goo.gl/maps/8k9JJRSNN7gtSSaW9" target="_blank">
                hier
              </Link>{' '}
              oder{' '}
              <Link href="https://goo.gl/maps/G3mZYmzbf72xfd288" target="_blank">
                hier
              </Link>
              . Das Alte Gemeindehaus hat die Adresse:{' '}
              <Link href="https://goo.gl/maps/ijwraUVcottrRsC68" target="_blank">
                Alleenstraße 118, 73230 Kirchheim unter Teck
              </Link>
              .
            </Typography>
          </IconBlock>

          <IconBlock icon={RiverPlate} title="18:00 Uhr Abendessen">
            <Typography paragraph align="justify">
              Am Buffet habt ihr freie Wahl was ihr essen mögt. In der Rückmeldung könnt ihr dafür eure Essensvorlieben
              eintragen, das erleichtert uns die Planung der Mengen.
            </Typography>
          </IconBlock>

          <IconBlock icon={WeddingCake} title="21:00 Uhr Kuchenbuffet">
            <Typography paragraph align="justify">
              Kuchen darf natürlich an unserer Hochzeit nicht fehlen! Wir freuen uns über eure Kuchenspenden.
            </Typography>
          </IconBlock>
        </Grid>
      </PageSection>

      <Typography variant="h3" align="center" style={{ width: '100%', marginTop: '10px' }}>
        Weiteres
      </Typography>

      <PageSection>
        <IconBlock icon={Platter} title="Fingerfood und Kuchen">
          <Typography paragraph align="justify">
            Wir würden uns über einen Beitrag für das Fingerfood am Sektempfang, oder einen Kuchen für den Abend freuen.
            Den Kuchen könnt ihr vor dem Gottesdienst an der Kirche abgeben. Für den Weitertansport und die Kühlung
            sorgen wir.
          </Typography>
        </IconBlock>

        <IconBlock icon={Microphone} title="Abendprogramm">
          <Typography paragraph align="justify">
            Wir freuen uns, wenn der Abend lebendig gestaltet wird und jeder, der möchte, sich einbringen kann. Tragt
            dazu einfach in der Rückmeldung ein, wenn ihr etwas zum Abendprogramm beitragen möchtet. Unsere Trauzeugen
            schauen dann, dass wir den richtigen Platz im Abendprogramm finden.
          </Typography>
        </IconBlock>

        <IconBlock icon={NewlyWeds} title="Dresscode">
          <Typography paragraph align="justify">
            Es gibt keinen Dresscode. Kommt bitte so, dass ihr euch wohl fühlt.
          </Typography>
        </IconBlock>

        <IconBlock icon={FaceMask} title="Corona Regelungen">
          <Typography paragraph align="justify">
            Wir hoffen sehr, dass am 16. Oktober die Bedingungen so sind, dass wir alle gemeinsam feiern können. Sollte
            dies nicht möglich sein melden wir uns bei einzelnen von euch.
          </Typography>
          <Typography paragraph align="justify">
            Allgemein gilt aber in jedem Fall, wir halten uns an die 3G-Regel. Bitte kommt mit
            Geimpft-/Genesen-Nachweis, oder einem gültigen Schnelltest-Ergebnis.
          </Typography>
        </IconBlock>

        <IconBlock icon={Highchair} title="Kinderstühle">
          <Typography paragraph align="justify">
            Leider gibt es in unserer Location nur wenige Kinderstühle. Es wäre gut, wenn ihr deshalb selbst einen
            mitbringt. Falls das nicht möglich ist, gebt uns einfach Bescheid!
          </Typography>
        </IconBlock>

        <IconBlock icon={WeddingGift} title="Geschenke">
          <Typography paragraph align="justify">
            Wenn ihr euch unsicher seid was wir uns schenken könnt, dann würden wir uns über einen finanziellen Beitrag
            zu unseren nächsten Reisen freuen.
          </Typography>
        </IconBlock>
      </PageSection>

      <Grid item xs={12}>
        <Button variant="contained" href={routes.hochzeitBilder} target="_blank">
          Zu den Bildern
        </Button>
      </Grid>
    </PageContainer>
  );
};

const IconBlock: React.FC<{ icon: React.FC<React.SVGProps<SVGSVGElement>>; title: string }> = (props) => {
  return (
    <>
      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <div style={{ width: '45px', textAlign: 'center' }}>
          <props.icon style={{ height: '28px', flexShrink: 0, marginRight: 10 }} />
        </div>
        <Typography variant="h4">{props.title}</Typography>
      </Grid>

      <div>{props.children}</div>
    </>
  );
};
