import * as React from 'react';

import { ReactComponent as ChurchSvg } from './church.svg';
import { ReactComponent as ChampagneGlassSvg } from './champagne-glass.svg';
import { ReactComponent as RiverPlateSvg } from './river-plate.svg';
import { ReactComponent as WeddingGiftSvg } from './wedding-gift.svg';
import { ReactComponent as HighchairSvg } from './highchair.svg';
import { ReactComponent as WeddingCakeSvg } from './wedding-cake.svg';
import { ReactComponent as FaceMaskSvg } from './face-mask.svg';
import { ReactComponent as NewlyWedsSvg } from './newlyweds.svg';
import { ReactComponent as ChampagneSvg } from './champagne.svg';
import { ReactComponent as MicrophoneSvg } from './microphone.svg';
import { ReactComponent as PlatterSvg } from './platter.svg';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  icon: {
    height: '100%',
  },
});

export const Church: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <ChurchSvg className={styles.icon} {...props} />;
};

export const ChampagneGlass: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <ChampagneGlassSvg className={styles.icon} {...props} />;
};

export const RiverPlate: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <RiverPlateSvg className={styles.icon} {...props} />;
};

export const WeddingGift: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <WeddingGiftSvg className={styles.icon} {...props} />;
};

export const Highchair: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <HighchairSvg className={styles.icon} {...props} />;
};

export const WeddingCake: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <WeddingCakeSvg className={styles.icon} {...props} />;
};

export const FaceMask: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <FaceMaskSvg className={styles.icon} {...props} />;
};

export const NewlyWeds: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <NewlyWedsSvg className={styles.icon} {...props} />;
};

export const Champagne: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <ChampagneSvg className={styles.icon} {...props} />;
};

export const Microphone: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <MicrophoneSvg className={styles.icon} {...props} />;
};

export const Platter: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();
  return <PlatterSvg className={styles.icon} {...props} />;
};
