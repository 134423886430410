import * as React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Route, Switch, useLocation } from 'react-router-dom';

import { Home } from './pages/Home';
import { Anmeldung } from './pages/hochzeit/Anmeldung';
import { AnmeldungSuccess } from './pages/hochzeit/AnmeldungSuccess';
import { Hochzeit } from './pages/Hochzeit';
import { routes } from './config';
import { mainPrimary } from './theme/theme';
import { Info } from './pages/hochzeit/Info';
import { Auswahl } from './pages/hochzeit/Auswahl';
import { Bilder } from './pages/hochzeit/Bilder';
import { BilderUpload } from './pages/hochzeit/BilderUpload';
import { BilderGalerie } from './pages/hochzeit/BilderGalerie';
import { Abmeldung } from './pages/hochzeit/Abmeldung';
import { AbmeldungSuccess } from './pages/hochzeit/AbmeldungSuccess';
import { Impressum } from './pages/Impressum';
import { ImpressumLink } from './components/ImpressumLink';

const useStyles = makeStyles({
  app: (props: { center: boolean }) => ({
    backgroundColor: mainPrimary,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: props.center ? 'center' : 'space-between',
    padding: '20px 10px',
    fontSize: '12px',
    color: '#fff',
  }),
});

const App: React.FC = () => {
  const location = useLocation();

  const homeRouteActive = location.pathname === routes.home;

  const styles = useStyles({ center: homeRouteActive });

  return (
    <div className={styles.app}>
      <Switch>
        <Route path={routes.hochzeitInfo}>
          <Info />
        </Route>
        <Route path={routes.hochzeitAuswahl}>
          <Auswahl />
        </Route>
        <Route path={routes.hochzeitAnmeldung}>
          <Anmeldung />
        </Route>
        <Route path={routes.hochzeitAnmeldungSuccess}>
          <AnmeldungSuccess />
        </Route>
        <Route path={routes.hochzeitAbmeldung}>
          <Abmeldung />
        </Route>
        <Route path={routes.hochzeitAbmeldungSuccess}>
          <AbmeldungSuccess />
        </Route>
        <Route path={routes.hochzeitBilderStandesamt}>
          <BilderGalerie id="2020-11-27-standesamt" name="Standesamtliche Hochzeit" />
        </Route>
        <Route path={routes.hochzeitBilderPortraits}>
          <BilderGalerie id="2021-10-16-portraits" name="Portraits" />
        </Route>
        <Route path={routes.hochzeitBilderTrauung}>
          <BilderGalerie id="2021-10-16-trauung" name="Kirchliche Trauung" />
        </Route>
        <Route path={routes.hochzeitBilderFeier}>
          <BilderGalerie id="2021-10-16-feier" name="Feier in Kirchheim" />
        </Route>
        <Route path={routes.hochzeitBilderUpload}>
          <BilderUpload />
        </Route>
        <Route path={routes.hochzeitBilder}>
          <Bilder />
        </Route>
        <Route path={routes.hochzeit}>
          <Hochzeit />
        </Route>
        <Route path={routes.impressum}>
          <Impressum />
        </Route>
        <Route path={routes.home}>
          <Home />
        </Route>
      </Switch>
      {!homeRouteActive && <ImpressumLink />}
    </div>
  );
};

export default App;
