import * as React from 'react';

import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import * as Sentry from '@sentry/react';

import { PageHeader } from '../../components/page/PageHeader';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSection } from '../../components/page/PageSection';
import { apiBaseUrl, routes } from '../../config';
import { useLocalStorage } from '../../util/useLocalStorage';
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(1),
  },
  progressBar: {
    marginBottom: theme.spacing(1),
  },
  nameInput: {
    marginBottom: theme.spacing(1),
  },
  circularProgress: {
    float: 'left',
    marginTop: '5px',
    marginRight: theme.spacing(1),
  },
  moreImagesButton: {
    marginLeft: theme.spacing(1),
  },
}));

type UploadNotStarted = { type: 'UploadNotStarted' };
type UploadComplete = { type: 'UploadComplete' };
type UploadFailed = { type: 'UploadFailed' };
type UploadRunning = {
  type: 'UploadRunning';
  progress: number;
};

export const BilderUpload: React.FC = () => {
  const classes = useStyles();
  const inputRef = React.createRef<HTMLInputElement>();

  const [name, setName] = useLocalStorage('file-upload-name', '');
  const [fileList, setFileList] = React.useState<File[]>([]);
  const [uploadState, setUploadState] = React.useState<
    UploadNotStarted | UploadRunning | UploadComplete | UploadFailed
  >({
    type: 'UploadNotStarted',
  });

  const onFilesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = [];
    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files.item(i)!);
      }
    }
    setFileList(files);
  };

  const startUpload = async () => {
    setUploadState({ type: 'UploadRunning', progress: 0 });

    try {
      for (let index = 0; index < fileList.length; index++) {
        setUploadState({ type: 'UploadRunning', progress: index });
        const file = fileList[index];
        const uploadTicket = await axios.post(`${apiBaseUrl}/hochzeit-bild-upload`, { name: name, file: file.name });
        const uploadUrl = uploadTicket.data.uploadUrl;

        await axios.put(uploadUrl, await file.arrayBuffer(), {
          headers: { 'Content-Range': `bytes 0-${file.size - 1}/${file.size}` },
        });
      }
      setUploadState({ type: 'UploadComplete' });
    } catch (e) {
      Sentry.captureException(e);
      setUploadState({ type: 'UploadFailed' });
    }
  };

  return (
    <>
      <input
        type="file"
        multiple
        accept="image/*"
        onChange={onFilesSelected}
        ref={inputRef}
        style={{ display: 'none' }}
      />
      <PageContainer>
        <PageHeader title="Bilder Upload" />
        <PageSection>
          <Grid item xs={12}>
            {uploadState.type === 'UploadNotStarted' && (
              <>
                <Typography paragraph>Wir freuen uns sehr, dass du deine Bilder mit uns teilen willst.</Typography>
                <TextField
                  label="Dein Name"
                  helperText="Damit wir wissen von wem die Bilder sind"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={classes.nameInput}
                />
                <Typography paragraph>
                  <Button variant="contained" onClick={() => inputRef.current?.click()}>
                    {fileList.length > 0 ? `${fileList.length} Bilder ausgewählt` : 'Bilder auswählen'}
                  </Button>
                </Typography>
                {fileList.length > 0 && (
                  <>
                    <Typography paragraph>Klicke "Upload starten" um deine Bilder hochzuladen.</Typography>
                    <Button variant="contained" onClick={startUpload}>
                      Upload starten
                    </Button>
                  </>
                )}
              </>
            )}

            {uploadState.type === 'UploadRunning' && (
              <>
                <Typography paragraph>Deine Bilder werden jetzt hochgeladen, dies kann ein bisschen dauern.</Typography>
                <Typography paragraph>
                  Bitte währenddessen nicht zu einer anderen App wechseln oder dein Smartphone ausschalten, sonst könnte
                  der Upload abbrechen.
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={(uploadState.progress / fileList.length) * 100}
                  className={classes.progressBar}
                />
                <CircularProgress size="16px" className={classes.circularProgress} />
                <Typography paragraph>
                  Bild {uploadState.progress + 1} von {fileList.length} wird hochgeladen.
                </Typography>
              </>
            )}

            {uploadState.type === 'UploadComplete' && (
              <>
                <Alert severity="success" className={classes.alert}>
                  Vielen Dank, wir haben deine Bilder bekommen!
                </Alert>
                <Button variant="contained" href={routes.hochzeitBilder}>
                  Zurück zur Übersicht
                </Button>
                <Button
                  variant="contained"
                  onClick={() => window.location.reload()}
                  className={classes.moreImagesButton}
                >
                  Weitere Bilder hochladen
                </Button>
              </>
            )}

            {uploadState.type === 'UploadFailed' && (
              <>
                <Alert severity="error" className={classes.alert}>
                  <b>Oh nein! Da ist etwas schief gelaufen.</b>
                  <p>Bitte probiere den Upload der Bilder noch einmal.</p>
                  Sollte es dann noch immer nicht klappen, melde dich bei uns, dann können wir das Problem beheben.
                </Alert>
                <Button variant="contained" href={routes.hochzeitBilder}>
                  Zurück zur Übersicht
                </Button>
                <Button
                  variant="contained"
                  onClick={() => window.location.reload()}
                  className={classes.moreImagesButton}
                >
                  Nochmal probieren
                </Button>
              </>
            )}
          </Grid>
        </PageSection>
      </PageContainer>
    </>
  );
};
