import * as React from 'react';

export interface GlobalState {
  hochzeit: {
    anmeldung: {
      names: string | undefined;
    };
  };
}

export type Dispatcher =
  | {
      type: 'CHANGE_NAMES';
      names: string;
    }
  | {
      type: 'DUMMY';
      payload: number;
    };

export interface ContextType {
  state: GlobalState;
  dispatch: (dispatch: Dispatcher) => void;
}

const initialState: GlobalState = {
  hochzeit: {
    anmeldung: {
      names: undefined,
    },
  },
};

const GlobalContext = React.createContext<ContextType>({ state: initialState, dispatch: () => {} });

export const GlobalStateProvider: React.FC = (props) => {
  const reducer: React.Reducer<GlobalState, Dispatcher> = (state, action) => {
    switch (action.type) {
      case 'CHANGE_NAMES':
        return {
          ...state,
          hochzeit: {
            ...state.hochzeit,
            anmeldung: {
              ...state.hochzeit.anmeldung,
              names: action.names,
            },
          },
        };

      default:
        return state;
    }
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return <GlobalContext.Provider value={{ state, dispatch }}>{props.children}</GlobalContext.Provider>;
};

export const useGlobalState = (): GlobalState => {
  return React.useContext(GlobalContext).state;
};

export const useGlobalDispatcher = (): ((dispatch: Dispatcher) => void) => {
  return React.useContext(GlobalContext).dispatch;
};
