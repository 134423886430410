import * as React from 'react';

import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import * as Sentry from '@sentry/react';

import App from './App';
import { theme } from './theme/theme';
import { GlobalStateProvider } from './context/GlobalContext';

if (window.location.hostname !== 'localhost')
  Sentry.init({ dsn: 'https://a1a0bca4797b437c9d9367d58155ad34@o982758.ingest.sentry.io/5938222' });

render(
  <React.StrictMode>
    <GlobalStateProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </GlobalStateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
