import * as React from 'react';

import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import './BilderGalerie.css';
import 'react-bnb-gallery/dist/style.css';
import ReactBnbGallery, { Photo } from 'react-bnb-gallery';

import { PageHeader } from '../../components/page/PageHeader';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSection } from '../../components/page/PageSection';
import { apiBaseUrl, routes } from '../../config';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export interface Props {
  id: string;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  photoList: {
    textAlign: 'center',
  },
  photo: {
    display: 'inline-block',
    height: '240px',
    width: '240px',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: theme.spacing(0.5),
    cursor: 'pointer',
  },
}));

export const BilderGalerie: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [photos, setPhotos] = React.useState<Array<Photo>>([]);
  const [focusedPhoto, setFocusedPhoto] = React.useState(0);
  const [highlightOpen, setHighlightOpen] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const response = await axios.get<{ photos: string[] }>(`${apiBaseUrl}/hochzeit-bilder?album=${props.id}`);
      setPhotos(
        response.data.photos.map((url) => ({
          photo: `${url}=w2000`,
          thumbnail: `${url}=w250`,
        }))
      );
    })();
  }, [props.id]);

  return (
    <>
      <ReactBnbGallery
        show={highlightOpen}
        photos={photos}
        activePhotoIndex={focusedPhoto}
        onClose={() => setHighlightOpen(false)}
      />
      <PageContainer maxWidth="1100px">
        <PageHeader title={`Bilder ${props.name}`} />

        <PageSection>
          <Grid item xs={12}>
            <Typography paragraph>
              Wenn du noch weitere Bilder hast, würden wir uns sehr freuen, wenn du Sie uns hier hochlädst.
            </Typography>
            <Button variant="contained" href={routes.hochzeitBilderUpload}>
              Bilder hochladen
            </Button>
          </Grid>
        </PageSection>

        <PageSection>
          <Grid item xs={12}>
            <Alert severity="info">Bilder antippen um sie in voller Größe zu sehen.</Alert>
          </Grid>
          <Grid item xs={12} className={classes.photoList}>
            {photos.length === 0 && <CircularProgress />}
            {photos.map((photo) => (
              <div
                key={photo.number}
                className={classes.photo}
                style={{ backgroundImage: `url(${photo.photo})` }}
                onClick={() => {
                  setHighlightOpen(true);
                  setFocusedPhoto(photos.indexOf(photo));
                }}
              />
            ))}
          </Grid>

          <Grid item xs={12}>
            <Button href={routes.hochzeitBilder}>Zurück zur Übersicht</Button>
          </Grid>
        </PageSection>

        <PageSection>
          <Grid item xs={12}>
            <Typography paragraph>
              Wenn du noch weitere Bilder hast, würden wir uns sehr freuen, wenn du Sie uns hier hochlädst.
            </Typography>
            <Button variant="contained" href={routes.hochzeitBilderUpload}>
              Bilder hochladen
            </Button>
          </Grid>
        </PageSection>
      </PageContainer>
    </>
  );
};
