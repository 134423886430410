import * as React from 'react';

import Grid from '@material-ui/core/Grid';

import { PageHeader } from '../../components/page/PageHeader';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSection } from '../../components/page/PageSection';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

export const AbmeldungSuccess: React.FC = () => {
  return (
    <PageContainer>
      <PageHeader title="Rückmeldung zur Hochzeit" />
      <PageSection>
        <Grid item xs={12}>
          <Typography variant="h3">Gespeichert!</Typography>
          <Typography>
            Sehr schade, dass ihr nicht kommen könnt. Hoffentlich sehen wir uns auch so bald mal wieder.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            Wenn ihr später noch etwas an euren Angaben ändern wollt, könnt ihr das Formular einfach noch einmal mit den
            neuen Informationen ausfüllen.
          </Alert>
        </Grid>
      </PageSection>
    </PageContainer>
  );
};
