import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../config';

export const Hochzeit: React.FC = () => {
  const history = useHistory();
  React.useEffect(() => {
    history.replace(routes.hochzeitInfo);
  }, [history]);

  return null;
};
