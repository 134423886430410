import * as React from 'react';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PageHeader } from '../../components/page/PageHeader';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSection } from '../../components/page/PageSection';
import { useHistory } from 'react-router-dom';
import { apiBaseUrl, routes } from '../../config';
import { useGlobalState } from '../../context/GlobalContext';
import { RequestError } from '../../components/feedback/RequestError';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
  },
}));

export const Anmeldung: React.FC = () => {
  const history = useHistory();
  const styles = useStyles();

  const names = useGlobalState().hochzeit.anmeldung.names;
  React.useEffect(() => {
    if (!names) {
      history.replace(routes.hochzeitAuswahl);
    } else {
      window.scrollTo({ top: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [vegetarianCount, setVegetarianCount] = React.useState<string>('');
  const [vegetarianCountError, setVegetarianCountError] = React.useState(false);
  const [veganCount, setVeganCount] = React.useState<string>('');
  const [veganCountError, setVeganCountError] = React.useState(false);
  const [fingerfood, setFingerfood] = React.useState('');
  const [cake, setCake] = React.useState('');
  const [program, setProgram] = React.useState('');
  const [questions, setQuestions] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);

  const submitForm = () => {
    if (!vegetarianCount) setVegetarianCountError(true);
    if (!veganCount) setVeganCountError(true);

    if (!vegetarianCount || !veganCount) {
      window.scrollTo({ top: 0 });
      return;
    }
    setSubmitting(true);

    axios
      .post(`${apiBaseUrl}/hochzeit-submit`, {
        names,
        action: 'Anmeldung',
        vegetarianCount: `${vegetarianCount}`,
        veganCount: `${veganCount}`,
        fingerfood,
        cake,
        program,
        questions,
      })
      .then((result) => {
        if (result.status === 200) {
          history.push(routes.hochzeitAnmeldungSuccess);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <PageContainer>
      <PageHeader title="Rückmeldung zur Hochzeit" />

      <PageSection>
        <Grid item xs={12}>
          <Typography variant="h3">Abendessen</Typography>
          <Typography>Schön, dass ihr gemeinsam mit uns unsere Hochzeit feiert!</Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl className={styles.select}>
            <InputLabel error={vegetarianCountError}>Anzahl der Vegetarier</InputLabel>
            <Select
              fullWidth
              value={vegetarianCount}
              onChange={(e) => {
                setVegetarianCount(e.target.value as string);
                setVegetarianCountError(false);
              }}
            >
              <MenuItem value="0">keine Vegetarier</MenuItem>
              <MenuItem value="1">1 Person</MenuItem>
              <MenuItem value="2">2 Personen</MenuItem>
              <MenuItem value="3">3 Personen</MenuItem>
              <MenuItem value="4">4 Personen</MenuItem>
              <MenuItem value="5">5 Personen</MenuItem>
            </Select>
            {vegetarianCountError && <FormHelperText error={true}>Dieses Feld muss ausgefüllt werden.</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl className={styles.select}>
            <InputLabel error={veganCountError}>Anzahl der Veganer</InputLabel>
            <Select
              fullWidth
              value={veganCount}
              onChange={(e) => {
                setVeganCount(e.target.value as string);
                setVeganCountError(false);
              }}
            >
              <MenuItem value="0">keine Veganer</MenuItem>
              <MenuItem value="1">1 Person</MenuItem>
              <MenuItem value="2">2 Personen</MenuItem>
              <MenuItem value="3">3 Personen</MenuItem>
              <MenuItem value="4">4 Personen</MenuItem>
              <MenuItem value="5">5 Personen</MenuItem>
            </Select>
            {veganCountError && <FormHelperText error={true}>Dieses Feld muss ausgefüllt werden.</FormHelperText>}
          </FormControl>
        </Grid>
      </PageSection>

      <PageSection>
        <Grid item xs={12}>
          <Typography variant="h3">Fingerfood &amp; Kuchen</Typography>
          <Typography>
            Wir freuen uns über Fingerfood und Kuchen, wenn ihr etwas mitbringen möchtet, füllt einfach die
            nachfolgenden Felder aus.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Wir bringen Fingerfood mit"
            value={fingerfood}
            onChange={(e) => setFingerfood(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField fullWidth label="Wir bringen Kuchen mit" value={cake} onChange={(e) => setCake(e.target.value)} />
        </Grid>
      </PageSection>

      <PageSection>
        <Grid item xs={12}>
          <Typography variant="h3">Abendprogramm &amp; Weiteres</Typography>
          <Typography>Füllt, wenn ihr etwas beitragen möchtet, einfach die nachfolgenden Felder aus.</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            label="Beitrag zum Abendprogramm"
            value={program}
            onChange={(e) => setProgram(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            label="Platz für Fragen &amp; Anmerkungen"
            value={questions}
            onChange={(e) => setQuestions(e.target.value)}
          />
        </Grid>
      </PageSection>

      {error && (
        <Grid item xs={12}>
          <RequestError />
        </Grid>
      )}

      <Grid item xs={12}>
        <Button variant="contained" color="secondary" disabled={submitting} onClick={submitForm}>
          {submitting ? (
            <>
              <CircularProgress size="1rem" /> &nbsp; Sende...
            </>
          ) : (
            'Abschicken'
          )}
        </Button>
      </Grid>

      <Grid item xs={12}></Grid>
    </PageContainer>
  );
};
