import * as React from 'react';

import { routes } from '../../config';
import { PageHeader } from '../../components/page/PageHeader';
import { PageContainer } from '../../components/page/PageContainer';
import { PageSection } from '../../components/page/PageSection';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useGlobalDispatcher } from '../../context/GlobalContext';

export const Auswahl: React.FC = () => {
  const [names, setNames] = React.useState('');
  const [namesError, setNamesError] = React.useState(false);
  const [route, setRoute] = React.useState<string | undefined>(undefined);
  const [routeError, setRouteError] = React.useState(false);

  const history = useHistory();
  const dispatch = useGlobalDispatcher();

  const navigate = () => {
    if (!names) setNamesError(true);
    if (!route) setRouteError(true);

    dispatch({ type: 'CHANGE_NAMES', names });

    if (names && route) history.push(route);
  };

  return (
    <PageContainer>
      <PageHeader title="Rückmeldung zur Hochzeit" />
      <PageSection>
        <Grid item xs={12}>
          <Typography variant="h3">Namen der Gäste</Typography>
          <Typography>
            Damit wir wissen wer ihr und wie viele ihr seid, tragt doch bitte alle eure Namen ein.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Namen der Gäste"
            error={namesError}
            helperText={namesError ? 'Dieses Feld muss ausgefüllt werden.' : undefined}
            value={names}
            onChange={(e) => {
              setNames(e.target.value);
              setNamesError(false);
            }}
          />
        </Grid>
      </PageSection>

      <PageSection>
        <Grid item xs={12}>
          <Typography variant="h3">Kommt ihr zu unserer Hochzeit?</Typography>
        </Grid>

        <Grid item xs={12}>
          <RadioGroup
            name="selection"
            onChange={(e) => {
              setRoute(e.target.value);
              setRouteError(false);
            }}
          >
            <FormControlLabel
              value={routes.hochzeitAnmeldung}
              control={<Radio color="primary" />}
              label="Ja, wir kommen!"
            />
            <FormControlLabel
              value={routes.hochzeitAbmeldung}
              control={<Radio color="primary" />}
              label="Nein, wir können leider nicht kommen."
            />
            {routeError && <FormHelperText error={true}>Hier muss eine Entscheidung getroffen werden.</FormHelperText>}
          </RadioGroup>
        </Grid>
      </PageSection>

      <Grid item xs={12}>
        <Button variant="contained" color="secondary" onClick={navigate}>
          {route === routes.hochzeitAbmeldung ? 'Abschicken' : 'Weiter'}
        </Button>
      </Grid>
    </PageContainer>
  );
};
