export const routes = {
  home: '/',
  impressum: '/impressum',
  hochzeit: '/hochzeit',
  hochzeitInfo: '/hochzeit/info',
  hochzeitAuswahl: '/hochzeit/auswahl',
  hochzeitAnmeldung: '/hochzeit/anmeldung',
  hochzeitAnmeldungSuccess: '/hochzeit/anmeldung-success',
  hochzeitAbmeldung: '/hochzeit/abmeldung',
  hochzeitAbmeldungSuccess: '/hochzeit/abmeldung-success',
  hochzeitBilder: '/hochzeit/bilder',
  hochzeitBilderStandesamt: '/hochzeit/bilder/standesamt',
  hochzeitBilderPortraits: '/hochzeit/bilder/portraits',
  hochzeitBilderTrauung: '/hochzeit/bilder/trauung',
  hochzeitBilderFeier: '/hochzeit/bilder/feier',
  hochzeitBilderUpload: '/hochzeit/bilder/upload',
};

export const apiBaseUrl =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3002/api' : 'https://api.nohe.family';
