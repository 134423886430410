import * as React from 'react';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Fingerprints } from '../components/icons/Fingerprints';
import { MailDialog } from '../components/MailDialog';
import { routes } from '../config';

const useStyles = makeStyles({
  home: {
    textAlign: 'center',
  },
  logo: {
    width: '80vw',
    maxWidth: '400px',
  },
});

export const Home: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();

  const [mailDialogOpen, setMailDialogOpen] = React.useState(false);

  return (
    <header className={styles.home}>
      <Fingerprints className={styles.logo} />
      <Typography variant="h1">nohe.family</Typography>
      <IconButton color="secondary" onClick={() => setMailDialogOpen(true)} title="Nachricht schreiben">
        <MailOutlineIcon />
      </IconButton>
      <IconButton
        color="secondary"
        onClick={() => history.push(routes.impressum)}
        title="Impressum &amp; Datenschutzerklärung"
      >
        <InfoOutlinedIcon />
      </IconButton>
      <MailDialog open={mailDialogOpen} onClose={() => setMailDialogOpen(false)} />
    </header>
  );
};
