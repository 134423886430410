import * as React from 'react';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import { PageContainer } from '../components/page/PageContainer';
import { PageHeader } from '../components/page/PageHeader';
import { PageSection } from '../components/page/PageSection';

export const Impressum: React.FC = () => {
  return (
    <PageContainer>
      <PageHeader title="Datenschutzerklärung" />
      <PageSection>
        <Grid item xs={12}>
          <Typography>
            <p>
              Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO),
              ist:
            </p>
            <p>
              Jakob Nohe
              <br />
              Tübinger Weg 4
              <br />
              72622 Nürtingen
            </p>
            <h2>Ihre Betroffenenrechte</h2>
            <p>
              Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte
              ausüben:
            </p>
            <ul>
              <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
              <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
              <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
              <li>
                Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht
                löschen dürfen (Art. 18 DSGVO),
              </li>
              <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
              <li>
                Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns
                abgeschlossen haben (Art. 20 DSGVO).
              </li>
            </ul>
            <p>
              Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft
              widerrufen.
            </p>
            <p>
              Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige
              Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle
              zuständige Behörde.
            </p>
            <p>
              Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie
              <Link
                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                hier
              </Link>
              .
            </p>
            <p></p>
            <h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
            <h3>Art und Zweck der Verarbeitung:</h3>
            <p>
              Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig
              Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen
              (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen
              Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches.{' '}
            </p>
            <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
            <ul>
              <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
              <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
              <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
              <li>zu weiteren administrativen Zwecken.</li>
            </ul>
            <p>
              Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden
              von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu
              optimieren.
            </p>
            <h3>Rechtsgrundlage:</h3>
            <p>
              Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der
              Verbesserung der Stabilität und Funktionalität unserer Website.
            </p>
            <h3>Empfänger:</h3>
            <p>
              Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer
              Webseite als Auftragsverarbeiter tätig werden.
            </p>
            <h3>Speicherdauer:</h3>
            <p>
              Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist
              für die Daten, die der Bereitstellung der Webseite dienen, grundsätzlich der Fall, wenn die jeweilige
              Sitzung beendet ist.
            </p>
            <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>
              Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich
              vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
              gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus
              diesem Grund ist ein Widerspruch ausgeschlossen.{' '}
            </p>
            <p></p>
            <h2>Kontaktformular</h2>
            <h3>Art und Zweck der Verarbeitung:</h3>
            <p>
              Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert.
              Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der
              Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist
              optional.
            </p>
            <h3>Rechtsgrundlage:</h3>
            <p>
              Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines
              berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).
            </p>
            <p>
              Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme
              ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
              Anschlussfragen gespeichert.
            </p>
            <p>
              Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das
              Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b
              DSGVO).
            </p>
            <h3>Empfänger:</h3>
            <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
            <h3>Speicherdauer:</h3>
            <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
            <p>
              Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach
              HGB und löschen Ihre Daten nach Ablauf dieser Fristen.{' '}
            </p>
            <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>
              Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur
              bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
            </p>
            <p></p>
            <h2>Verwendung von Google Analytics</h2>
            <h3>Art und Zweck der Verarbeitung:</h3>
            <p>
              Diese Website benutzt Google Analytics, einen Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway,
              Mountain View, CA 94043 USA (nachfolgend: „Google“). Google Analytics verwendet sog. „Cookies“, also
              Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite
              durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite
              werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Aufgrund der
              Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb
              von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
              Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
              Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird
              Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die
              Webseitenaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
              verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im Rahmen von Google
              Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
              zusammengeführt.
            </p>
            <p>
              Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung der Website und in der
              Zusammenstellung von Reports über Aktivitäten auf der Website. Auf Grundlage der Nutzung der Website und
              des Internets sollen dann weitere verbundene Dienstleistungen erbracht werden.
            </p>
            <h3>Rechtsgrundlage:</h3>
            <p>
              Die Verarbeitung der Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a
              DSGVO).
            </p>
            <h3>Empfänger:</h3>
            <p>
              Empfänger der Daten ist Google als Auftragsverarbeiter. Hierfür haben wir mit Google den entsprechenden
              Auftragsverarbeitungsvertrag abgeschlossen.
            </p>
            <h3>Speicherdauer:</h3>
            <p>
              Die Löschung der Daten erfolgt, sobald diese für unsere Aufzeichnungszwecke nicht mehr erforderlich sind.
            </p>
            <h3>Drittlandtransfer:</h3>
            <p>
              Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen{' '}
              <Link href="https://www.privacyshield.gov/EU-US-Framework">
                https://www.privacyshield.gov/EU-US-Framework
              </Link>
              .{' '}
            </p>
            <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>
              Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung.
              Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der Website kommen.
            </p>
            <h3>Widerruf der Einwilligung:</h3>
            <p>
              Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
              verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
              Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der
              durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an
              Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link
              verfügbare Browser-Plugin herunterladen und installieren:{' '}
              <Link href="http://tools.google.com/dlpage/gaoptout?hl=de">
                Browser Add On zur Deaktivierung von Google Analytics
              </Link>
              .
            </p>
            <h3>Profiling:</h3>
            <p>
              Mit Hilfe des Tracking-Tools Google Analytics kann das Verhalten der Besucher der Webseite bewertet und
              die Interessen analysiert werden. Hierzu erstellen wir ein pseudonymes Nutzerprofil.
            </p>
            <p></p>
            <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
            <h3>Art und Zweck der Verarbeitung:</h3>
            <p>
              Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf
              dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
              nachfolgend „Google“) zur Darstellung von Schriften.
            </p>
            <p>
              Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier:{' '}
              <Link href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</Link>
            </p>
            <h3>Rechtsgrundlage:</h3>
            <p>
              Rechtsgrundlage für die Einbindung von Google Webfonts und dem damit verbundenen Datentransfer zu Google
              ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).{' '}
            </p>
            <h3>Empfänger:</h3>
            <p>
              Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber
              der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu
              welchen Zwecken – dass der Betreiber in diesem Fall Google Daten erhebt.
            </p>
            <h3>Speicherdauer:</h3>
            <p>Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Webfonts.</p>
            <p>
              Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
              <Link href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</Link> und in
              der Datenschutzerklärung von Google:{' '}
              <Link href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</Link>.
            </p>
            <h3>Drittlandtransfer:</h3>
            <p>
              Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen{' '}
              <Link href="https://www.privacyshield.gov/EU-US-Framework">
                https://www.privacyshield.gov/EU-US-Framework
              </Link>
              .
            </p>
            <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>
              Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch vertraglich vorgeschrieben.
              Allerdings kann ggfs. die korrekte Darstellung der Inhalte durch Standardschriften nicht möglich sein.
            </p>
            <h3>Widerruf der Einwilligung:</h3>
            <p>
              Zur Darstellung der Inhalte wird regelmäßig die Programmiersprache JavaScript verwendet. Sie können der
              Datenverarbeitung daher widersprechen, indem Sie die Ausführung von JavaScript in Ihrem Browser
              deaktivieren oder einen JavaScript-Blocker installieren. Bitte beachten Sie, dass es hierdurch zu
              Funktionseinschränkungen auf der Website kommen kann.
            </p>
            <p></p>
            <h2>SSL-Verschlüsselung</h2>
            <p>
              Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der
              Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
            </p>
            <p></p>
            <h2>Änderung unserer Datenschutzbestimmungen</h2>
            <p>
              Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen
              Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
              z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
            </p>
            <h2>Fragen an den Datenschutzbeauftragten</h2>
            <p>
              Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an
              die für den Datenschutz verantwortliche Person in unserer Organisation:
            </p>
            <p></p>
            <p>
              <em>
                Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für{' '}
                <Link
                  href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  externe Datenschutzbeauftragte
                </Link>{' '}
                (Version #2019-04-10).
              </em>
            </p>
          </Typography>
        </Grid>
      </PageSection>
    </PageContainer>
  );
};
